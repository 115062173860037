import React from 'react'
import { Input } from 'react-daisyui'
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useCouponStore } from '../Store/CouponStore';
import { useState } from 'react';
import { useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddCoupenManagement = observer(() => {

    const navigate = useNavigate();
    const [startdatestate, setstartdatestate] = useState(null);
    const {
    getname,
    getcode,
    getdiscount,
    getdiscount_type,
    startdate,
    getstartdate,
    getenddate,
    getstatus,
    addCoupon,
        saved,
      } = useCouponStore();
    useEffect(() => {
      if (saved) {
        navigate("../couponmanagement");
      }
    }, [saved]);
  return (
   <>
    <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold text-moredarkgreen">Add Coupon</p>
                {/* <p className=" opacity-80 text-sm">Home / Coupon / Add</p> */}
            </div>
            <div>
                <Link to="../couponmanagement" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen ">Back</Link>
            </div>
        </div>
        <div className="card bg-base-100 shadow-md p-6 mt-8">
            <form onSubmit={(e) => {addCoupon();e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Coupon Name<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Coupon Name"
                        required
                        placeholder="Coupon Name" 
                        onChange={(e) => getname(e.target.value)}
                        id="Coupon Name" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    {/* <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Coupon Code<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Coupon Code"
                        required
                        placeholder="Coupon Code" 
                        onChange={(e) => getcode(e.target.value)}
                        id="Coupon Code" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div> */}
                    <div>
                        <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Discount Type<span className="text-[#f00]">&nbsp;*</span></label>
                        <select 
                          name="Discount Type"
                          defaultValue=""
                        required
                        onChange={(e) => getdiscount_type(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                           <option value="" disabled className="hidden">
                            Select Discount Type
                            </option>
                            <option value={'flat'}>Flat</option>
                            <option value={'percentage'}>Percentage</option>
                        </select>
                    </div>
 
                    <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Discount<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Discount"
                        required
                        placeholder="Discount" 
                        onChange={(e) => getdiscount(e.target.value)}
                        id="Discount" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="startdate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Start Date<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="startdate" 
                        placeholder="" 
                        onChange={(e) => getstartdate(e.target.value)}
                        id="startdate" 
                        type="date" 
                        autoComplete="off" 
                        required
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>

                    <div>
                        <label htmlFor="enddate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">End Date<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="enddate" 
                        placeholder="" 
                        onChange={(e) => getenddate(e.target.value)}
                        id="enddate" 
                        type="date" 
                        autoComplete="off" 
                        required
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
        
                
                    <div>
                        <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
                        <select 
                        name="Status"
                        required
                        onChange={(e) => getstatus(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                        <option hidden value="" className="hidden">
                        Select Status
                        </option>
                            <option value={'1'}>Active</option>
                            <option value={'0'}>Inactive</option>
                        </select>
                    </div>
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="../couponmanagement" className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineClose className="mr-1"/>Cancel</Link>
                        <button className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineCheck className="mr-1"/>Add Coupon</button>
                </div>
            </form>
        </div>
   </>
  )
});

export default AddCoupenManagement