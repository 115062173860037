import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData, postFormData } from "../../ApiMethods/api";
import { toast } from "react-toastify";


export class FaqStore {

  Token = null;
  loadCmslist = false;
  title="";
  meta_keyword="";
  meta_description="";
  content="";
  saved=false;
  startdate="";
  enddate="";
  discount="";
  discount_type="";
  status="";
  faq = [];
  errloadFaq = "";
  nolist = false;
  loadFaq = false;
  faq_id = "";
  answer = "";
  nofilterwithpagination= false;
  filtertrue=false;
  indexPage=1;
  activePage=1;
  question= ""

  previousValues = {
    question: "",
    status:"",
  };
  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      this.getFaq();
      return
    }
    if (isloading.editloading) {
      this.getfaqbyid(isloading.id);
      return
    }
  }
  getbytitle=(text)=>{
    this.title = text;
  }
  getcontent=(text)=>{
    this.content = text;
  }
  getfaqid=(text)=>{
    this.faq_id = text;
  }
  getstatus = (text) => {
      this.status = text;
  };
  //answer in add
  addanswer=(text)=>{
    this.answer = text;
  }

  NofilterwithPagination = (e) => {
    runInAction(() => {
        this.nofilterwithpagination = e
    })
  }
  setfiltertrue=(e)=>{
    this.filtertrue = e;
  }

  setquestion = (text) =>  {
    this.question = text;
}


  // get Faq list
  getFaq = () => {
    runInAction(() => {
      this.loadFaq = true;
      this.errloadFaq = "";
      this.saved = false;
    });
    getMethodData("/admin/faqs", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadFaq = false;
            this.errloadFaq = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadFaq = false;
            this.errloadFaq = resp?.data?.message;
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.faq = resp?.data?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadFaq = false;
          this.errloadFaq = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadFaq = false;
          if (!error.response) {
            this.errloadFaq = error?.message;
            toast.error(error?.message);
          }
          this.errloadFaq = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };

  //Active and inactive
  statusChange = () => {
    runInAction(() => {
      this.loadSeason = true;
      this.errloadseason = "";
    });
    getMethodData(`/admin/faqs/change-status/${this.faq_id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadFaq = false;
            this.errloadFaq = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadFaq = false;
            this.errloadFaq = resp?.data?.message;
            this.getFaq();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.loadFaq = false;
          this.errloadFaq = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadFaq = false;
            this.errloadFaq = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadFaq = false;
          this.errloadFaq = error?.response?.data?.message;
        })
        return;
      })
  };

//   //get Faq by id
  getfaqbyid = (id) => {
    runInAction(() => {
      this.loadFaq = true;
      this.errloadFaq = "";
    });

    getMethodData(`/admin/faqs/edit/${id}`, this.Token)
      .then((resp) => {
       console.log(resp)
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadFaq = false;
            this.errloadFaq = resp.data.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
          this.loadFaq = false;
          this.errloadFaq = resp?.data?.message;
          this.title = resp?.data?.data?.question;
          this.meta_keyword = resp?.data?.data?.meta_keyword;
          this.meta_description = resp?.data?.data?.meta_description;
          this.content = resp?.data?.data?.answer;
          this.status = resp?.data?.data?.status;
          })
          return;
        }
        runInAction(() => {
          this.loadFaq = false;
          this.errloadFaq = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadFaq = false;
            this.errloadFaq = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadFaq = false;
          this.errloadFaq = error?.response?.data?.message;
        })
        return;
      })
  }


//   //update method
  updatedfaq = (id) => {
    runInAction(() => {
      this.loadFaq = true;
      this.errloadFaq = "";
    });
    const formData = new FormData();
    formData.append("question", this.title);
    formData.append("answer", this.content);
    formData.append("status", this.status);
    postFormData(`/admin/faqs/update/${id}`,
      formData,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadFaq = false;
            this.errloadFaq = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.errloadFaq = false;
              this.erraddcity = resp?.data?.message;
              toast.success(resp.data.message);
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.loadFaq = false;
          this.errloadFaq = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.loadFaq = false;
              this.errloadFaq = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.loadFaq = false;
              this.errloadFaq = error?.response?.data?.message;
        })
        return;
    })
  };


  // add faq method
  addfaq = () => {
    runInAction(() => {
      this.loadFaq = true;
      this.errloadFaq = "";
    });
    const formData = new FormData();
    formData.append("question", this.title);
    formData.append("answer", this.answer);
    formData.append("status", this.status);
    postFormData(`/admin/faqs/store`,
      formData,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadFaq = false;
            this.errloadFaq = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.errloadFaq = false;
              this.erraddcity = resp?.data?.message;
              toast.success(resp.data.message);
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.loadFaq = false;
          this.errloadFaq = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.loadFaq = false;
              this.errloadFaq = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.loadFaq = false;
              this.errloadFaq = error?.response?.data?.message;
        })
        return;
    })
  };



  //filter user api 

  getFilterFaq = (page) => {
    runInAction(() => {
        this.activePage = 1;
        this.loadCategory = true;
        this.indexPage = 1;
        this.filtertrue=false;
        this.errloadCategory = "";
        this.saved = false;
        this.faq=[];
        if(!this.nofilterwithpagination){
          this.previousValues = {
            answer: this.name,
            status: this.status,
          };
        }
      });
  
      if(this.nofilterwithpagination){
        getMethodData(`/admin/faqs?page=${page}&question=${ this.previousValues?.question}&status=${ this.previousValues?.status}}`,this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loadCategory = false;
                this.errloadCategory = resp?.data?.message
              });
              return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.loadCategory = false;
                    this.setfiltertrue(true);
                    this.errloadCategory = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
                    if(resp?.data?.data?.data?.length === 0) {
                        this.nolist = true;
                    }else{
                        this.nolist = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.faq = resp?.data?.data?.data;
                    this.Count = resp.data.data.total;
                    this.perPage = resp.data.data.per_page;
                    this.saved = false;
                    // this.status = true;
                    });
              return;
            }
            runInAction(() => {
              this.loadCategory = false;
              this.errloadCategory = resp?.data?.message;
            });
          })
          return;
        
      }
    getMethodData(`/admin/faqs?page=${page}&question=${this.question}&status=${this.status}`,this.Token)
    .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCategory = false;
            this.errloadCategory = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
            runInAction(() => {
                this.loadCategory = false;
                this.setfiltertrue(true);
                this.errloadCategory = resp?.data?.message;
                // console.log(resp?.data?.data.length);
                if(resp?.data?.data?.data?.length === 0) {
                    this.nolist = true;
                }else{
                    this.nolist = false;
                }
                // console.log(resp?.data?.data?.data)
                this.faq = resp?.data?.data?.data;
                this.Count = resp.data.data.total;
                this.perPage = resp.data.data.per_page;
                this.saved = false;
                // this.status = true;
                });
          return;
        }
        runInAction(() => {
          this.loadCategory = false;
          this.errloadCategory = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            this.loadCategory = false;
            if(!error.response){
                this.errloadCategory = error?.message;
                toast.error(error?.message);
            }
            this.errloadCategory = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
        })
        return;
    });
};
}




export const FaqStoreContext = createContext(new FaqStore());
export const useFaqStore = () => useContext(FaqStoreContext);