import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import Pagination from "react-js-pagination";
import { RxEyeOpen, RxPencil1  } from 'react-icons/rx';
import { useOrderStore,OrderStore,OrderStoreContext } from "./store/OrderStore";
import Filter from "./OrderFilter/OrderFilter";
import { BsBagCheck } from "react-icons/bs";
import { useEffect } from "react";

const OrderManagement = () => {
    const store = new OrderStore({
      isloading:true,
      pagginationcount:1
    });
    return(
      <OrderStoreContext.Provider value={store}>
        <Screen/>
      </OrderStoreContext.Provider>
    )
  }
  
  export default OrderManagement;

  const Screen = observer(()=>{

    const {
      loadTableData,
      setActivePage,
      setIndexPage,
      getFilterOrders,
      NofilterwithPagination,
      getOrders,
      filtertrue,
      indexPage,
      filterVisibility,
      activePage,
      perPage,
      Count,
        Orders,
        loadOrder,
        nolist
      } = useOrderStore();
      const handlePageChange = (pageNumber) => {
        NofilterwithPagination(true);
        const element = document.getElementById("tablescroll");
     if (element) {
       element.scrollIntoView({ behavior: "smooth" });
     }
     if(filtertrue){
      getFilterOrders(pageNumber)
      setIndexPage(pageNumber);
      setActivePage(pageNumber);
     }else{
      if (filterVisibility === true) {
        getOrders(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      } else {
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      }
     }
   };

    return(
      <>
      <div className='flex justify-between'>
      <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">Purchase Report</p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        
       </div>
       <Filter/>
       <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                {/* <th>Transaction ID</th> */}
                <th>Order Id</th>
                <th>TRANSACTION ID</th>
                <th>Product </th>
                <th>Amount</th>
                <th>Customer</th>
                {/* <th>Customer Email</th> */}
                <th>Payment Method</th>
                <th>Order Date</th>
                <th>Order Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody id="tablescroll" className="text-sm relative">

            {loadOrder && 
              <>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

              {
                Orders && Orders.map((res,index) => (
                    <tr key={index}>
                        <td> {perPage * (indexPage - 1) + (index + 1)}</td>
                        {/* <td className=" capitalize">#1023545</td> */}
                        <td className=" capitalize">#{res?.order_number}</td>
                        <td className=" capitalize">{res?.transaction_id ? `#${res?.transaction_id}` : "---"}</td>
                        <td className=" capitalize">{ res?.category?.type?.name || res?.product?.product_type?.name || "---" }  <p className="capitalize text-xs"> { res?.category?.name || res?.product?.name || "---"} </p></td>
                        <td className=" capitalize">${res?.final_order_amount}</td>
                        <td className=" capitalize"><span className=" font-bold">{res?.customer?.first_name + " " + res?.customer?.last_name}</span> <p className="capitalize text-xs">{res?.customer?.email}</p></td>
                        <td className=" capitalize">{res?.payment_method ?? "---"}</td>
                        <td className=" capitalize"><Moment format="MM/DD/YYYY">{res.order_date_time ?? "N/A"}</Moment></td>
                        <th>
                            <label
                            title="Change Status"
                            htmlFor="my-modal-5"
                            className={`btn capitalize ${res?.order_status === "Completed" ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                            onClick={(e) => {
                            }}
                            >
                            {res?.order_status}
                            </label>
                        </th>
                        {/* <td className=" text-base">
                            <div className="flex items-center space-x-4">
                            <button title="View Order">
                                <Link to={`/ordermanagement/${res?.id}`}>
                                    <RxEyeOpen />
                                </Link>
                            </button>
                            </div>
                        </td> */}
                    </tr>
                ))
              }
             {nolist && 
              <tr>
                <td colSpan={9} className="my-10 text-center">
                  <BsBagCheck className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Order Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        <Pagination
                        activePage={activePage}
                        containerClassName={'pagination'}
                        itemsCountPerPage={perPage}
                        totalItemsCount={Number(Count)}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                      />
        {/* status change model start */}
        {/* <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4"
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
          {/* status change model end */}
        </div>
    </div>
       </>
    )
  })