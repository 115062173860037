import { observer } from "mobx-react";
import React, { useEffect } from 'react'
import { Input } from 'react-daisyui'
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useProfileStore,ProfileStoreContext,ProfileStore } from './store/Profilestore'
import { useAuthStore } from "../Auth/store/Auth";

// const Profile = observer (() => {
//     return (
//         <ProfileStoreContext.Provider
//           value={
//             new ProfileStore({
//               loadeditcitybol: false,
//               editloading: true,
//             //   id: id,
//             })
//           }
//         >
//           <Screen />
//         </ProfileStoreContext.Provider>
//       );
// })



const Profile = observer (()=>{

    const {
        firstname,
        lastname,
        email,
        updatedfirstname,
        getlastname,
        updateprofile,
        getprofileone
      } = useAuthStore();

      useEffect(() => {
        getprofileone()
      }, [])

  return (
   <>
      <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold text-moredarkgreen">Profile</p>
                {/* <p className=" opacity-80 text-sm">Home / Profile</p> */}
            </div>
            <div>
                <Link to="../" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen ">Back</Link>
            </div>
        </div>

        <div className="card bg-base-100 shadow-md p-6 mt-8">
            <form onSubmit={(e) => {updateprofile();e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                        <label htmlFor="first_name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">First Name<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="first_name" 
                        onChange={(e) => {updatedfirstname(e.target.value)}}
                        value={firstname}
                        required
                        placeholder="First Name" 
                        id="name" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Last Name<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="last_name" 
                        onChange={(e) => {getlastname(e.target.value)}}
                        value={lastname}
                        required
                        placeholder="Last Name" 
                        id="name" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Email<span className="text-red-500">&nbsp;*</span></label>
                        <Input 
                        name="email" 
                        disabled
                        value={email}
                        placeholder="Email" 
                        id="email" 
                        type="email" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="../" className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineClose className="mr-1"/>Cancel</Link>
                        <button className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineCheck className="mr-1"/>Update</button>
                </div>
            </form>
        </div>
   </>
  )
})
export default Profile


