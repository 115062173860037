import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_URL;
const CHAT_BASE_URL = process.env.REACT_APP_CHAT_BASE_URL;
const ADMIN_CHAT_CHANNEL_KEY=process.env.REACT_APP_ADMIN_CHAT_CHANNEL_KEY

// get and post method api admin

export const postLogin = async (API_URL,Data) => {
    return(
       await axios.post(`${BASE_URL}${API_URL}`,Data)
    )
}

/**
 * **getAdminChatMethodData method is used to get admin group chat**
 * @param {API_URL} API_URL 
 * @param {API Key} key 
 * @returns 
 */

export const getAdminChatMethodData = async (API_URL) => {
  return(
     await axios.get(`${CHAT_BASE_URL}${API_URL}`,{ headers: { "api_key": `${ADMIN_CHAT_CHANNEL_KEY}` } })
  )
}

/**
 * **postAdminChatMethodData method is used to send admin group chat to db**
 * @param {API_URL} API_URL 
 * @param {API Key} key 
 * @returns 
 */

export const postAdminChatMethod = async (API_URL, data) => {
  return(
      await axios.post(`${CHAT_BASE_URL}${API_URL}`,data,{ headers: { "api_key": `${ADMIN_CHAT_CHANNEL_KEY}` } })
  )
}

export const deleteAdminGroupMethod = async (API_URL, data) => {
  return(
    await axios.delete(`${CHAT_BASE_URL}${API_URL}`,{ headers: { "api_key": `${ADMIN_CHAT_CHANNEL_KEY}` } ,data})
  )
}


export const getMethodData = async (API_URL,Token) => {
    return(
        
       await axios.get(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${Token}` } })
    )
}

//get chat group data list
export const getChatMethodData = async (API_URL,Token) => {
    return(
        
       await axios.get(`${CHAT_BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${Token}` } })
    )
}

export const patchChangeMethod = async (API_URL, data) => {
  return(
    await axios.patch(`${CHAT_BASE_URL}${API_URL}`,data,{ headers: { "api_key": `${ADMIN_CHAT_CHANNEL_KEY}` }})
  )
}
//change chat group isHide status api

export const patchStatusChangeMethod = async (API_URL,data,Token) => {
  return(
      await axios.post(`${CHAT_BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${Token}` } })
  )
}

//chat group delete api

export const deleteGroupMethod = async (API_URL, data, TokenGet) => {
  return(
     await axios.delete(`${CHAT_BASE_URL}${API_URL}`, { data: data, headers: { authorization: `Bearer ${TokenGet}` } })
  )
}

export const postMethodData = async (API_URL,data,Token) => {
    return(
        await axios.post(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${Token}` } })
    )
}


  export const postFormData = async (API_URL, formData, Token) => {
    try {
      const response = await fetch(`${BASE_URL}${API_URL}`, {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${Token}`,
        },
        body: formData,
      });
      const responseData = await response.text();
      const data = responseData && JSON.parse(responseData);
      if (!response.ok || !data) {
        throw new Error('Failed to fetch data from API');
      }
      const newData = {
        data: data
      }
      return newData;
    } catch (error) {
      // Handle the error here
      // console.log('An error occurred while trying to post login:', error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  };

export const patchMethodData = async (API_URL,data,Token) => {
    return(
        await axios.patch(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${Token}` } })
    )
}


export const getSubmitData = async (API_URL,TokenGet) => {
    return(
       await axios.get(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${TokenGet}` } })
    )
}
export const putMethod = async (API_URL,data,TokenGet) => {
    return(
       await axios.put(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${TokenGet}` } })
    )
}
export const delMethod = async (API_URL,TokenGet) => {
    return(
       await axios.delete(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${TokenGet}` } })
    )
}