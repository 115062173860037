import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Route,Routes,useNavigate} from "react-router-dom";
import Login from "./Auth/Login";
import Header from "./common/header";
import Sidebar from "./common/Sidebar";
import Routess from "./routes";
import { observer } from "mobx-react";
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuthStore} from "./Auth/store/Auth"


const App = observer(() => {
  const {adminAuthApproved,logout} = useAuthStore();
  
  useEffect(()=>{
    axios.interceptors.request.use(function (config) {
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      switch (response.status) {
          case 400:
            break;
          case 401:
          logout();
            break;
          case 500:
            break;
          case 504:
            break;
          default:
      }
      return response;
    }, function (error) {
      switch (error.response.status) {
        case 400:
          break;
        case 401:
        logout();
          break;
        case 500:
          break;
        case 504:
          break;
        default:
      }
      return Promise.reject(error);
    });
  },[])
  return (
      <>
        {adminAuthApproved
        ?
        <div className="flex">
        <Sidebar/>
        <main className="w-full bg-[#f3f3f3] block">
        <Header/>
          <section className="h-auto overflow-hidden w-full p-8">
            <Routess />
          </section>
        </main> 
        </div>
        :
        <Routes>
          <Route element={<Login/>} path="/" />
          <Route element={<Login/>} path="*" />
        </Routes>
        }
        <ToastContainer />
      </>
  );
})

export default App;