import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input } from "react-daisyui"
import { observer } from "mobx-react";
import { useCouponStore } from "../Store/CouponStore";

const Filter = observer(() => {
    const {
        getname,
        getcode,
        getdiscount,
        getdiscount_type,
        getstartdate,
        getenddate,
        getstatus,
        addCoupon,
        getCoupon,
        getFilterCoupen,
            saved,
          } = useCouponStore();
          const Reset=()=>{
            getname("")
            getcode("")
            getstartdate("")
            getdiscount_type("")
            getenddate("")
            getstatus("")
        }
    return (
        <>
            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {getFilterCoupen(); e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Coupon Name</label>
                        <Input 
                        name="Coupon Name"
                        placeholder="Coupon Name" 
                        onChange={(e) => getname(e.target.value)}
                        id="Coupon Name" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Discount Type</label>
                        <select 
                          name="Discount Type"
                          defaultValue=""
                        onChange={(e) => getdiscount_type(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                           <option value="" className="">
                            All
                            </option>
                            <option value={'flat'}>Flat</option>
                            <option value={'percentage'}>Percentage</option>
                        </select>
                    </div>
                    {/* <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Coupon Code</label>
                        <Input 
                        name="Coupon Code"
                        placeholder="Coupon Code" 
                        onChange={(e) => getcode(e.target.value)}
                        id="Coupon Code" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div> */}
                    <div>
                        <label htmlFor="startdate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Start Date</label>
                        <Input 
                        name="startdate" 
                        placeholder="" 
                        onChange={(e) => getstartdate(e.target.value)}
                        id="startdate" 
                        type="date" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>

                    <div>
                        <label htmlFor="enddate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">End Date</label>
                        <Input 
                        name="enddate" 
                        placeholder="" 
                        onChange={(e) => getenddate(e.target.value)}
                        id="enddate" 
                        type="date" 
                        autoComplete="off"
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                  
                    <div>
                        <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label>
                        <select 
                        name="Status"
                        
                        onChange={(e) => getstatus(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                        <option value="" className="">
                        All
                        </option>
                            <option value={'active'}>Active</option>
                            <option value={'in-active'}>Inactive</option>
                        </select>
                    </div>


                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button onClick={()=>{getCoupon(); Reset()}}   type='reset'className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineRefresh className="mr-1" />Reset</button>
                        <button className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineSearch className="mr-1" />Search</button>
                    </div>
                </form>
            </div>

        </>
    )
})
export default Filter;