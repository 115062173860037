import React from "react";
// import { Tooltip } from 'react-daisyui'
import ReactApexChart from 'react-apexcharts';
import { HomeStore, HomeStoreContext, useHomeStore } from "../Store/HomeStore";
import { observer } from "mobx-react";

const Graph = () => {
  const store = new HomeStore({
    isloading: true,
    editloading: false,
  });
  return (
    <HomeStoreContext.Provider value={store}>
      <Screen />
    </HomeStoreContext.Provider>
  );
};
export default Graph;


const Screen = observer(() => {
  const {
    dashboarddata,
  } = useHomeStore();


  const options1 = {
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val 
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    xaxis: {
      categories: dashboarddata.chart_data?.users_chart_data?.labels,
      position: 'top',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#f7c962',
            colorTo: '#ffb101',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val 
        },
      },
    },
    title: {
      text: 'Total Customers',
      offsetY: -3, // Adjust the offsetY to position the title at the top
      align: 'left',
      style: {
        color: '#2f5542',
        fontSize: "19px",
        
      },
    },
    colors: dashboarddata.chart_data?.users_chart_data.backgroundColor, // Custom color for the bars
  };
  const options2 = {
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val 
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    xaxis: {
      categories: dashboarddata.chart_data?.orders_chart_data?.labels,
      position: 'top',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#f7c962',
            colorTo: '#ffb101',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val 
        },
      },
    },
    title: {
      text: 'Total Purchases',
      offsetY: -3, // Adjust the offsetY to position the title at the top
      align: 'left',
      style: {
        color: '#2f5542',
        fontSize: "19px",
        
      },
    },
    colors: dashboarddata.chart_data?.orders_chart_data.backgroundColor, // Custom color for the bars
  };
  const options3 = {
    chart: {
      height: 350,
      type: 'line',
    },
    forecastDataPoints: {
      count: 7,
    },
    stroke: {
      width: 5,
      curve: 'smooth',
    },
    xaxis: {
      categories: dashboarddata.chart_data?.sell_chart_data?.labels,
      position: 'bottom',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#f7c962',
            colorTo: '#ffb101',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    title: {
      text: 'Total Earnings',
      align: 'left',
      style: {
        color: '#2f5542',
        fontSize: "19px",
        
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#FDD835'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    yaxis: {
      categories: dashboarddata.chart_data?.sell_chart_data?.data,
      position: 'left',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#f7c962',
            colorTo: '#ffb101',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
  };
  const options4 = {
    chart: {
      height: 350,
      type: 'line',
    },
    forecastDataPoints: {
      count: 7,
    },
    stroke: {
      width: 5,
      curve: 'smooth',
    },
    xaxis: {
      categories: dashboarddata.chart_data?.withdrawals_chart_data?.labels,
      position: 'bottom',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#f7c962',
            colorTo: '#ffb101',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    title: {
      text: 'Total Withdrawal',
      align: 'left',
      style: {
        color: '#2f5542',
        fontSize: "19px",
        
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#FDD835'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    yaxis: {
      categories: dashboarddata.chart_data?.sell_chart_data?.data,
      position: 'left',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#f7c962',
            colorTo: '#ffb101',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
  };
  const series1 = [
    {
      name: 'Customers',
      data: dashboarddata.chart_data?.users_chart_data?.data,
    },
  ];
  const series2 = [
    {
      name: 'Orders',
      data: dashboarddata.chart_data?.orders_chart_data?.data,
    },
  ];
  const series3 = [
    {
      name: 'Sales',
      data:  dashboarddata.chart_data?.sell_chart_data?.data?.flatMap((data, index) =>data.toFixed(2)),
    },
  ];
  const series4 = [
    {
      name: 'Sales',
      data:  dashboarddata.chart_data?.withdrawals_chart_data?.data?.flatMap((data, index) =>data.toFixed(2)),
    },
  ];

  return (
    <>
       <div className=" grid xl:grid-cols-2 mt-8 gap-8">
        <div className="card bg-base-100 shadow-md p-6 xl:col-span-1">
        <div id="chart">
    <ReactApexChart options={options1} series={series1} type="bar" height={350} />
  </div>
        </div>
    
        <div className="card bg-base-100 shadow-md p-6 xl:col-span-1">
        <div id="chart">
      <ReactApexChart options={options3} series={series3} type="line" height={350} />
    </div>
  </div>
  <div className="card bg-base-100 shadow-md p-6 xl:col-span-1">
        <div id="chart">
    <ReactApexChart options={options2} series={series2} type="bar" height={350} />
  </div>
        </div>
        <div className="card bg-base-100 shadow-md p-6 xl:col-span-1">
        <div id="chart">
      <ReactApexChart options={options4} series={series4} type="line" height={350} />
    </div>
  </div>
      </div>
    </>
  );
});



