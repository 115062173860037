import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import { RxPencil1} from 'react-icons/rx';
import { useProductStore,ProductStore,ProductStoreContext } from "./store/ProductStore";
import Filter from "./ProductFilter/productfilter";
import { MdAdd, MdProductionQuantityLimits } from "react-icons/md";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { getMethodData } from "../ApiMethods/api";
import axios from "axios";

const ProductManagement = () => {
    const store = new ProductStore({
      isloading:true,
      editloading:false,
      pagginationcount:1
    });
    return(
      <ProductStoreContext.Provider value={store}>
        <Screen/>
      </ProductStoreContext.Provider>
    )
  }
  
  export default ProductManagement;

  const Screen = observer(()=>{
    const { id } = useParams();
    const [isid,setisid] = useState(false)

    const {
      products,
      loadTableData,
    getProducts,
      sendPage,
      setActivePage,
      setIndexPage,
      indexPage,
      Count,
      perPage,
      filterVisibility,
      activePage,
      loadproduct,
      nolist,
      filtertrue,
      NofilterwithPagination,
      getFilterProducts,
      statusChange,
      getstatusid,
    } = useProductStore();

    useEffect(() => {
      if(id){
        setisid(true)
        getFilterProducts(1,id)
      }
    },[isid,id])
   
   
  
    // useEffect(() => {
    //   loadTableData(1);
    // }, []);






    const handlePageChange = (pageNumber) => {
      NofilterwithPagination(true);
         const element = document.getElementById("tablescroll");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      if(filtertrue){
        getFilterProducts(pageNumber,id)
        setIndexPage(pageNumber);
        setActivePage(pageNumber);
       }else{
        if (filterVisibility === true) {
          getProducts(pageNumber);
          setActivePage(pageNumber);
          setIndexPage(pageNumber);
        } else {
          setActivePage(pageNumber);
          setIndexPage(pageNumber);
        }
       }
   
    };



    return(
      <>
      <div className='flex justify-between'>
      <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">Product Management</p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        {!isid 
        && 
        <div>
          <Link to="./addproduct" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "><MdAdd/>Add Product</Link>
        </div>
        }
        
       </div>
       {!isid && <Filter/>}
       <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Product Type</th>
                <th>Product Category</th>
                <th>Name</th>
                <th>Price</th>
                <th>Product Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="tablescroll" className="text-sm relative">
            {loadproduct && 
              <>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={7} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

            {products && products?.map((data,index)=> (
              <tr  key={index}>
                <td>                  {perPage * (indexPage - 1) + (index + 1)}
</td>

<td className=" capitalize">{data?.product_type?.name}</td>
<td className=" capitalize">{data?.category?.name}</td>

                <td className=" capitalize">{data?.product_type_id != 3 && data?.product_type_id != 4 ?  data?.name:"---"}</td>
                <td className=" capitalize">{data?.product_type_id != 3 && data?.product_type_id != 4 ?`$${data?.price} `: "---" } </td>
                
             
                <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${data.status ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getstatusid(data?.id);
                      }}
                    >
                      {data.status ? "Active" : "Inactive"}
                    </label>
                  </th>
                <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        // onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                      <button title="Edit">
                        <Link to={`/productmanagement/${data?.id}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td>
                </tr>
             ))}
             
            {nolist && 
              <tr>
                <td colSpan={7} className="my-10 text-center">
                  <MdProductionQuantityLimits className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Product Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        {/* <ReactPaginate
      //  initialPage={1}
        pageCount={Math.ceil(products.length / ITEMS_PER_PAGE)}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        activeClassName={'active'}
      
        /> */}

<Pagination
                        activePage={activePage}
                        containerClassName={'pagination'}
                        itemsCountPerPage={perPage}
                        totalItemsCount={Number(Count)}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                      />

           {/* <ReactPaginate
                        activePage={activePage}
                        marginPagesDisplayed={2}
                       
                        itemsCountPerPage={perPage}
                        totalItemsCount={Number(Count)}
                        pageRangeDisplayed={10}
                        onPageChange={handlePageChange}
                      /> */}
        {/* status change model start */}
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
       </>
    )
  })