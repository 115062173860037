import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { RxDashboard, RxPerson, RxGear, RxHome, RxCrosshair1, RxCalendar } from "react-icons/rx";
import { BiCategory, BiDollarCircle } from "react-icons/bi";
import { BsBagCheck, BsSpeedometer2 } from "react-icons/bs";
import { MdCalendarToday, MdLocationCity, MdOutlinedFlag, MdOutlineLocationOn, MdOutlineManageAccounts, MdPages, MdSportsHandball, MdSportsTennis,MdAttachMoney, MdProductionQuantityLimits, MdOutlineRateReview, MdReportGmailerrorred } from "react-icons/md";
import Logo from "../assets/images/wokiilogowhite.png"
import { NavLink} from "react-router-dom";


const Navbar = () => {
  const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
  return (
    <Sidebar
      id="sidebar"
      style={{minWidth: "68px"}}
      className="md:sticky fixed left-0 md:translate-x-0 -translate-x-full h-screen md:top-0 top-[70px] font-roboto z-50"
      backgroundColor="#2f5542"
    >
      <div className="h-[70px] flex items-center justify-center">
        {/* <img className=" h-full mr-2 py-3" alt="..." src={Logo}/> */}
        <span className="text-[#fff] font-bold text-xl pl-[12px] pr-[80px]">{
          <img src={Logo}/>
        }</span>
      </div>
      <Menu>
        <NavLink className="link-main" to="/">
          <MenuItem icon={<BsSpeedometer2/>} title="Dashboard" > 
              Dashboard
          </MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/usermanagement">
          <MenuItem icon={<RxPerson />} title="User Management">User Management</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/groupmanagement">
          <MenuItem icon={<RxPerson />} title="Group Management">Group Management</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/categorymanagement">
          <MenuItem icon={<BiCategory />} title="Category Management">Category Management</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/productmanagement">
          <MenuItem icon={<MdProductionQuantityLimits />} title="Product Management">Product Management</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/ordermanagement">
          <MenuItem icon={<BsBagCheck />} title="Purchase Report">Purchase Report</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/paymentsmanagement">
          <MenuItem icon={<BiDollarCircle />} title="Wallet Topup Report">Wallet Topup Report</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/withdrawalmanagement">
          <MenuItem icon={<BiDollarCircle />} title="Withdrawal Report">Withdrawal Report</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/myearningmanagement">
          <MenuItem icon={<BiDollarCircle />} title="Reconciliation Report">Reconciliation Report</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/contactmanagement">
          <MenuItem icon={<BiDollarCircle />} title="Enquiry List">Enquiry List</MenuItem>  
        </NavLink>
        {/* <NavLink className="link-main" to="/reviewsmanagement">
          <MenuItem icon={<MdOutlineRateReview />} title="Review Management">Review Management</MenuItem>  
        </NavLink> */}
        {/* <NavLink className="link-main" to="/couponmanagement">
          <MenuItem icon={<MdReportGmailerrorred />} title="Coupon Management">Coupon Management</MenuItem>  
        </NavLink> */}
        <NavLink className="link-main" to="/cmsmanagement">
          <MenuItem icon={<MdPages />} title="Cms Management">CMS Management</MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/faqmanagement">
          <MenuItem icon={<MdPages />} title="FAQ Management">FAQ Management</MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/channelmanagement">
          <MenuItem icon={<MdPages />}>Channel Management</MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/reporteduser">
          <MenuItem icon={<MdReportGmailerrorred />} title="Reported users List">Reported users List</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/setting">
          <MenuItem icon={<RxGear />} title="Settings">Settings</MenuItem>
        </NavLink>
      </Menu>
    </Sidebar>
  );
};

export default Navbar;
