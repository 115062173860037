import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import {  RxEyeOpen, RxPencil1 } from 'react-icons/rx';
import { useCategoryStore,CategoryStore,CategoryStoreContext } from "./store/CategoryStore";
import Filter from "./CategoryFilter/categoryfilter";
import { MdAdd } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { useEffect } from "react";

const CategoryManagement = () => {
    const store = new CategoryStore({
      isloading:true,
      editloading:false,
      pagginationcount:1
    });
    return(
      <CategoryStoreContext.Provider value={store}>
        <Screen/>
      </CategoryStoreContext.Provider>
    )
  }
  
  export default CategoryManagement;

  const Screen = observer(()=>{

    const {
      categorys,
      loadCategory,
      nolist,
      filterVisibility,
      activePage,
      Count,
      perPage,
      indexPage,
      filtertrue,
      nofilterwithpagination,
      NofilterwithPagination,
      setActivePage,
      getCategorys,
      setIndexPage,
      statusChange,
      getstatusid,
      getFilterCategory
    } = useCategoryStore();
    const handlePageChange = (pageNumber) => {
      NofilterwithPagination(true);
      const element = document.getElementById("tablescroll");
   if (element) {
     element.scrollIntoView({ behavior: "smooth" });
   }
   if(filtertrue){
    getFilterCategory(pageNumber)
    setIndexPage(pageNumber);
    setActivePage(pageNumber);
   }else{
    if (filterVisibility === true) {
      getCategorys(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
    } else {
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
    }
   }

 };
//  useEffect(() => {
//   loadTableData(1);
// }, []);
    return(
      <>
      <div className='flex justify-between'>
      <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">Category Management</p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        <div>
          <Link to="./addcategory" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "><MdAdd/>Add Category</Link>
        </div>
       </div>
       <Filter/>
       <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>Product Type</th>
                <th>Price</th>
                <th>Category Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="tablescroll" className="text-sm relative">
            {loadCategory && 
              <>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

            {categorys && categorys?.map((data,index)=> (
              <tr key={index}>
                <td> {perPage * (indexPage - 1) + (index + 1)}</td>
                <td className=" capitalize">{data?.name}</td>
                <td className=" capitalize">{data?.type?.name}</td>
                <td className=" capitalize"> { data?.type?.name =="Wallpapers"? "--" :`$${data?.price}`??"--"}</td>
                {/* <td className=" capitalize">Smiley, Face, Cry</td> */}
                <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${data.status ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getstatusid(data?.id);
                      }}
                    >
                      {data.status ? "Active" : "Inactive"}
                    </label>
                  </th>
                <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        // onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                      <button title="Edit">
                        <Link to={`/categorymanagement/${data?.id}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                      <Link title="View Product" to={`/productmanagement/category/${data?.id}`}>
                          <RxEyeOpen />
                      </Link>
                    </div>
                  </td>
                </tr>
            ))}

            {nolist && 
              <tr>
                <td colSpan={6} className="my-10 text-center">
                  <BiCategory className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Category Found</p>
                </td>
              </tr>
            }


            </tbody>
        </table>
        <Pagination
          activePage={activePage}
          containerClassName={'pagination'}
          itemsCountPerPage={perPage}
          totalItemsCount={Number(Count)}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
        />

        {/* status change model start */}
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
       </>
    )
  })