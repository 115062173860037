import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  RxDashboard,
  RxEyeOpen,
  RxPencil1,
  RxPerson,
  RxPlus,
} from "react-icons/rx";
import {
  useChannelStore,
  ChannelStore,
  ChannelStoreContext,
} from "./store/ChannelStore";
// import Filter from "./UserFilter/userfilter";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import JoditEditor from "jodit-react";
import sanitizeHtml from "sanitize-html";
import { AES, enc, Utf8 } from "crypto-js";
import AdminMessageRender from "./adminMessageRender";
import { set } from "mobx";
import { format } from "date-fns";
import AWS from 'aws-sdk';
window.Buffer = window.Buffer || require("buffer").Buffer;

const ChannelManagement = () => {
  const store = new ChannelStore({
    isloading: true,
    editloading: false,
    pagginationcount: 1,
  });
  return (
    <ChannelStoreContext.Provider value={store}>
      <Screen />
    </ChannelStoreContext.Provider>
  );
};

export default ChannelManagement;

const Screen = observer(() => {
  const s3 = new AWS.S3({
      accessKeyId: 'AKIA4XC2J2WY252KZX57',
      secretAccessKey: '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',
      region: 'us-east-2',
      s3Url: "https://wokii-chat-staging.s3.us-east-2.amazonaws.com/",
      bucketName: "wokii-chat-staging"
  });
  const [open, setOpen] = useState(false);
  let currentDate = "";
  const [isHide, setIsHide] = useState(null);
  const [channelId, setChannelId] = useState("");
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // State for controlling the preview modal
  const editorRef = useRef("");
  const [preview,setpreview] =useState("")
  const inputFocusRef = useRef(null);
  // const [editorMessage, setEditorMessage] = useState("");
  const editorMessage = useRef("");
  const messagesEndRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const editorConfig = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    placeholder: "Message...",
    toolbarAdaptive: true,
    autofocus: true,
    cursorAfterAutofocus: "start", // 'end';
    saveSelectionOnBlur: true,
    readonly: false,
    toolbar: true,
    height: 200,
    overflow: "auto", // add overflow property
    minHeight: 200, // set a minimum height
    showCharsCounter: false, // Show characters counter
    showWordsCounter: false, // Show words counter
    showXPathInStatusbar: false, // Show path to selected element
    statusBar: false, // Hide status bar
    resize: false, // Disable resizable feature
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "ul",
      "ol",
      "|",
      // 'lineHeight',
      "font",
      "fontsize",
      "image",
      "video",
      // "file",
      "link",
      // "hr",
      //     {
      //       name: "video",
      //       icon: "video",
      //       exec: function (editor) {
      //          // Add styles to the editor's container
      // editor.container.style.whiteSpace = 'pre-wrap';
      // editor.container.style.wordWrap = 'break-word';
      //         const input = document.createElement("input");
      //         input.type = "file";
      //         input.accept = "video/*";
      //         input.onchange = function (event) {
      //           const file = event.target.files[0];
      //           const reader = new FileReader();
      //           reader.onload = function (e) {
      //             editor.s.insert(
      //               `<video controls src="${e.target.result}"></video>`
      //             );
      //           };
      //           reader.readAsDataURL(file);
      //         };
      //         input.click();
      //       },
      //       tooltip: "Insert video",
      //     },
    ],
    // uploader: {
    //   insertImageAsBase64URI: true,
    // },
  };
  const {
    DeletechatMessage,
    getAdminChatMethodData,
    getAdminChatMessagesData,
    postAdminChatMethodData,
    adminChatMessagesData,
    roomId,
    groupname,
    groupimage,
    adminChatMessage,
    users,
    loaduser,
    nolist,
    filterVisibility,
    activePage,
    perPage,
    Count,
    indexPage,
    filtertrue,
    getFilterUsers,
    loadTableData,
    getUsers,
    setIndexPage,
    setActivePage,
    statusChange,
    deleteGroup,
    getstatusid,
    nofilterwithpagination,
    NofilterwithPagination,
  } = useChannelStore();

  // console.log("admin chat messages data", adminChatMessagesData?.length);

  useEffect(() => {
    //  loadTableData(1);
    // getUsers();
    getAdminChatMethodData();
  }, []);

  useEffect(() => {
    if (roomId) {
      getAdminChatMessagesData(roomId);
    }
  }, [roomId]);

  // const config = useMemo(() => ({
  //   readonly: false,
  //   toolbar: true,
  //   buttons: ['bold', 'italic', 'underline', 'image'],
  // }), []);

  /**
   * * to handel JoditEditor open and close when click outside **
   */
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (editorRef.current && !editorRef.current.contains(event.target) && editorRef.current !== event.target) {
  //       setIsEditorOpen(false);
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [editorRef, setIsEditorOpen]);

  const handlePageChange = (pageNumber) => {
    NofilterwithPagination(true);
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (filtertrue) {
      getFilterUsers(pageNumber);
      setIndexPage(pageNumber);
      setActivePage(pageNumber);
    } else {
      if (filterVisibility === true) {
        getUsers(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      } else {
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      }
    }
  };

  function isValidUTF8(string) {
    try {
      decodeURIComponent(escape(string));
      return true;
    } catch (err) {
      return false;
    }
  }
  // console.log("editor message", editorMessage.current);
  // console.log("users in grpmngmt", users);

  // Function to clear the editor content
  const clearEditor = () => {
    if (editorRef.current) {
      editorRef.current.setContent(""); // Clear the editor content
    }
  };

  // if (document.activeElement === editorRef.current) {
  //   console.log("focused");
  //   // do something
  // }

 // useEffect(()=> {
 //   if(editorRef?.current){
  //    setpreview(editorRef.current.value)
  //    console.log("dsfsdfsdfsdf",editorRef.current.value)
//    }
 // },[editorRef])

//  const uploadToS3 = async (file) => {
//   const params = {
//       Bucket: 'wokii-chat-staging',
//       Key: "Document/" + file.name,
//       Body: file
//   };

//   return new Promise((resolve, reject) => {
//       s3.upload(params, (err, data) => {
//           if (err) {
//               reject(err);
//           } else {
//               resolve(data.Location); // Returns the URL of the uploaded image
//           }
//       });
//   });
// };

const imageuploadaws = async (incomingdata) => {
  if (incomingdata) {
    const imageDataMatch = incomingdata.match(/<img\s+[^>]*src="((?!(https)).*?)"[^>]*>/);
      if (imageDataMatch) {
          const imageDataUrl = imageDataMatch[1]; // Extract src attribute value
          try {
              const mimeType = imageDataUrl.split(':')[1].split(';')[0].split('/')[1];
              const binaryImageData = Buffer.from(imageDataUrl.replace(/^data:image\/\w+;base64,/, ""), 'base64');
              const uniqueKey = `Document/image-${Date.now()}-${Math.random().toString(36).substring(2)}.${mimeType}`;
              // Upload the binary data to AWS S3
              const params = {
                  Bucket: 'wokii-chat-staging',
                  Key: uniqueKey,
                  Body: binaryImageData,
                  ContentEncoding: 'base64',
                  ContentType: `image/${mimeType}`
              };
              const response = await s3.upload(params).promise();
              const imageUrl = response.Location;

              const newImageDataUrl = incomingdata.replace(imageDataUrl, `${imageUrl}`);
              console.log("newImageDataUrlnewImageDataUrl", newImageDataUrl);
              // Send the modified data using postAdminChatMethodData
              postAdminChatMethodData(
                  AES.encrypt(newImageDataUrl, process.env.REACT_APP_ENCRYPTION_KEY).toString()
              );
          } catch (error) {
              console.error('Error uploading image to AWS:', error);
          }
      } else {
          // If no img tag found, send the original data
          postAdminChatMethodData(
              AES.encrypt(incomingdata, process.env.REACT_APP_ENCRYPTION_KEY).toString()
          );
      }
  } else {
      // If no incoming data, send the original data
      postAdminChatMethodData(
          AES.encrypt(incomingdata, process.env.REACT_APP_ENCRYPTION_KEY).toString()
      );
  }
};

const scrollToBottom = () => {
  setTimeout(() => {
    if (messagesEndRef.current) {
      const container = messagesEndRef.current.parentElement; // Assuming messagesEndRef is the last message element
      const extraSpace = -100; // Adjust this value based on the space you want to leave
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      const additionalScroll = extraSpace + container.lastElementChild.clientHeight;
      const newScrollTop = maxScrollTop - additionalScroll;

      container.scrollTop = newScrollTop;
    }
  }, 100); // Adjust delay as needed
};

useEffect(() => {
  scrollToBottom();
}, [adminChatMessagesData]); // Scroll whenever messages change
 


  return (
    <div className="w-full flex flex-col -mt-8 h-[86vh]">
      {/* <!-- Header --> */}
      <div className="w-full bg-[#2f5542] py-2 px-3 bg-grey-lighter flex flex-row justify-between items-center">
        <div className="flex items-center">
          <div>
            <img className="w-10 h-10 rounded-full" src={`${groupimage}`} />
          </div>
          <div className="ml-4">
            <span>
              <p className="text-[#fff]">{groupname}</p>
            </span>
          </div>
        </div>
      </div>

      {/* <!-- Messages --> */}
      <div
        className="flex-1 overflow-hidden"
        style={{ backgroundColor: "#DAD3CC" }}
      >
        <div className="py-2 px-3 h-full overflow-auto">
          {adminChatMessagesData &&
            adminChatMessagesData?.length !== 0 &&
            adminChatMessagesData.map((message, index) => {
              const messageDate = format(
                new Date(message?.updatedAt),
                "MMMM d, yyyy"
              );
              let displayDate = null;
              if (messageDate !== currentDate) {
                currentDate = messageDate;
                displayDate = (
                  <div className="flex items-center justify-center rounded-md bg-[#c5c5c5] text-black px-2 my-3 w-max m-auto ">
                    {messageDate}
                  </div>
                );
              }

              return (
                <div key={index}>
                  {displayDate}
                  <div className="flex justify-end mb-2">
                    <div
                    //  onContextMenu={(e)=>handleContextMenu(message?._id,e)}
                      className="pt-2 px-6 max-w-[50%] relative bg-[#E2F7CB] overflow-hidden rounded-[10px]"
                    >
                      {<AdminMessageRender message={message?.message} />}
                        <div className="text-right text-xs text-grey-dark flex justify-between align-item-center py-[10px] mt-[10px]">
                            <div  
                              className=" cursor-pointer pr-2 relative z-50" title="Delete">
                            <div onClick={()=>{
                                const isConfirmed = window.confirm('Are you sure you want to delete this message?');
                                if (isConfirmed) {
                                  DeletechatMessage(message?._id)
                                }
                              }}><RiDeleteBin6Line color="red" size={16} />
                            </div>
                            </div>
                        <p className="ml-auto">{format(new Date(message?.updatedAt), "h:mm a")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
             <div ref={messagesEndRef} />
        </div>
      </div>
       <p style={{
        fontSize:"14px",
        color:"red"
      }}>*Note: Channel Image should have aspect ratio 9:11</p>


      {/* <!-- Input --> */}
      <div
        className="bg-grey-lighter pt-4 flex items-center"
        style={{
          width: "-webkit-fill-available",
          background: "#2f5542",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
        }}
      >
        <div className="flex-1 mr-4">
          {/* {isEditorOpen ? */}
          <JoditEditor
            ref={editorRef}
            config={{
              ...editorConfig,
            }}
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              wordBreak: "break-word",
            }}
            onChange={(newContent) => (
              (editorRef.current.value = newContent)
            )}
          />
          {/* :
        <input 
        style={{display:"ruby-text"}}
        placeholder="Start Writing..."
        className="min-h-[70px] w-full p-2 focus:outline-none text-start"
        onClick={()=> {
          setIsEditorOpen(true)
        }} type="text" ref={inputFocusRef}/>
          
        } */}
        </div>

        {/*---------------------send button-----------------*/}

        <label
          title="Preview Message"
          htmlFor="my-modal-5"
          className={`btn btn-xs bg-transparent border-none hover:bg-transparent hover:border-none mr-4`}
       //</div>   
            onClick={(e) => {
                setpreview(editorRef.current.value)
            }}
          >
           <svg
            style={{ height: "28px", width: "28px",fill:"#fff" }}
            height="48"
            viewBox="0 0 48 48"
            width="48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.02 42l41.98-18-41.98-18-.02 14 30 4-30 4z" />
            <path d="M0 0h48v48h-48z" fill="none" />
          </svg>
        </label>

       {/* 
        <div
          className="cursor-pointer"
          onClick={() => {
            console.log("editorRef", editorRef.current.value);
            //const msg = editorRef.current.value;
            //console.log("message clicked", msg);

            // editorRef.current.value = "";

            // postAdminChatMethodData(
            //   AES.encrypt(msg, process.env.REACT_APP_ENCRYPTION_KEY).toString()
            // );

            // editorRef.current.value = "";
            // if (editorRef.current) {
            //   editorRef.current.setContent(""); // Clear the editor content
            // }
          }}
        >
          <svg
            style={{ height: "28px", width: "28px" }}
            height="48"
            viewBox="0 0 48 48"
            width="48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.02 42l41.98-18-41.98-18-.02 14 30 4-30 4z" />
            <path d="M0 0h48v48h-48z" fill="none" />
          </svg>
        </div>
      */}

        {/* preview message model start */}
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal chat-box">
            <div className="modal-box p-0 relative max-w-[390px] h-[844px]">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <div 
              className="text-sm innerbubble" 
              style={{ whiteSpace: "normal" }} 
              dangerouslySetInnerHTML={{ __html: preview }} 
              />
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {
                      // console.log("ssssssssss",editorRef.current.value)
                      imageuploadaws(editorRef.current.value)
                      editorRef.current.value = "";
                  }}
                >
                  Send
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  Cancel
                </label>
              </div>
            </div>
          </div>
          {/* preview message model end */}

      </div>
    </div>
  );
});
