import React, { useState } from "react";
import { getMethodData, postFormData, putMethod } from "../ApiMethods/api";
import { observer } from "mobx-react";
import {
  SettingStore,
  useSettingStore,
  UserSettingContext,
} from "./SettingStore/SettingStore";
import { Link } from "react-router-dom";
import { MdAdd, MdCancel, MdEdit } from "react-icons/md";
import { ButtonGroup, Input } from "react-daisyui";
import { useCallback } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Setting = () => {
  const store = new SettingStore({
    isloading: true,
    editloading: false,
  });
  return (
    <UserSettingContext.Provider value={store}>
      <Screen />
    </UserSettingContext.Provider>
  );
};
export default Setting;

const Screen = observer(() => {
  const {
    setting,
    buttonCheck,
    editbtncheck,
    newcheck,
    disabled,
    disabledCheck,
    disabledon,
    Getkey,
    getvalue,
    getnewfeilds,
    newfields,
    getSetting,
  } = useSettingStore();

  const Token = localStorage.getItem("Admintoken");
  // const [formDatanew, setFormDatanew] = useState([]);

  // console.log(formDatanew)

  function onsubmit(e) {
    e.preventDefault();
    // const request_data = {
    //   data: formData,
    // };
    const fd = new FormData();
    const getUpdateInputs = document.getElementsByClassName('settingUpdateInput');

    for (let i = 0; i < getUpdateInputs.length; i++) {
            
      if ( getUpdateInputs[i].type === "file" ) 
      {
          if(getUpdateInputs[i].files[0])
          {
              const getFile = getUpdateInputs[i].files[0]
              fd.append(getUpdateInputs[i].id, getFile)
          }
      }
      else 
      {
          fd.append(getUpdateInputs[i].id, getUpdateInputs[i].value)
      }
  }

    postFormData(`/admin/settings/update`, fd, Token)
      .then((res) => {
        if (res?.data?.status) {
          disabledon();
          newcheck();
          getSetting();
          toast.success("Settings are updated successfully.");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("Error is", err);
      });
  }

  return (
    <div>
      <form onSubmit={(e) => {onsubmit(e);e.preventDefault()}}>
            <div className="flex justify-between">
              <div className=" mb-4">
                <p className=" text-2xl font-semibold text-moredarkgreen">Settings</p>
              </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                {setting && setting.map((data, index) => (
                    <div key={index}>
                      <label htmlFor={data?.key} className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                        {data?.label}
                      </label>

                      { data?.input_type !== "select" && data?.input_type !== "file"
                      ? 
                        <input
                            name={data?.key}
                            key={data?.key}
                            placeholder={data?.label}
                            id={data?.key}
                            defaultValue={data?.value}
                            type={data?.input_type}
                            required
                            autoComplete="off"
                            disabled={data?.key == "MIRROR_API_KEY" ? true : !newfields}
                            className="settingUpdateInput input settingUpdateInput focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                          />
                          
                        : 

                        (data?.input_type === "file") 
                        ?
                        <div className="grid grid-cols-3">
                          <input
                            name={data?.key}
                            // placeholder={data?.label}
                            id={data?.key}
                            // defaultValue={data?.value}
                            type={data?.input_type}
                            // required
                            disabled={!newfields}
                            autoComplete="off"
                            className="settingUpdateInput col-span-2 input settingUpdateInput focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                          />
                          <img className=" w-20" src={data?.value}/>
                        </div>
                        :
                        <select
                            defaultValue={data?.value}
                            required
                            disabled={data?.key == "SHOW_MIRROR_API_AVTAR" ? true : !newfields}
                            name={data?.key}
                            id={data?.key}
                            // onChange={(e) => getyear(e.target.value)}
                            className={`settingUpdateInput select settingUpdateInput focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                          >
                            {data?.input_options?.map((res,index)=> (
                              <option key={index}>{res}</option>
                            ))}
                          </select>
                      }
                    </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between">
              <div className=" mb-4"></div>
              {editbtncheck ? (
                <>
                  <div className="mt-3 flex space-x-3 ">
                    <button
                      type="submit"
                      className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "
                    >
                      Update
                    </button>
                    <div
                      onClick={(e) => {
                        newcheck();
                        disabledon();
                      }}
                      className=""
                    >
                      <button className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4">
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="mt-3">
                  <button
                    onClick={(e) => {
                      buttonCheck();
                      disabledCheck();
                      getnewfeilds();
                    }}
                    className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"
                  >
                    <MdEdit className="mr-2" />
                    Edit
                  </button>
                </div>
              )}
            </div>
          </form>

    </div>
  );
});
