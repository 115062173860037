import React, { useEffect, useState } from 'react'
import { Input } from 'react-daisyui'
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useCouponStore, CouponStore , CouponStoreContext } from "../Store/CouponStore";

const EditCoupenManagement = observer(() => {
    const { id } = useParams();
    return(
        <CouponStoreContext.Provider
        value={
          new CouponStore({
            loadeditcitybol: false,
            editloading: true,
            id: id,
          })
        }
      >
        <Screen />
      </CouponStoreContext.Provider>


    )

});
  export default EditCoupenManagement;
  
  const Screen = observer(() => {
    const {
        getname,
        getcode,
        getdiscount,
        getdiscount_type,
        getstartdate,
        getenddate,
        getstatus,
        updatedcoupon,
        name,
        code,
        discount,
        discount_type,
        status,
        saved,
        startdate,
        enddate
          } = useCouponStore();
          const { id } = useParams();
          const navigate = useNavigate();
          const [startdatestate,setstartdatestate] = useState(null)
          useEffect(() => {
            if (saved) {
              navigate("../couponmanagement");
            }
            if(startdatestate === null ? true: false){
              
            }
          }, [saved,startdatestate,startdate]);
  return (
   <>
    <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold text-moredarkgreen">Edit Coupon</p>
                {/* <p className=" opacity-80 text-sm">Home / Coupon / Edit</p> */}
            </div>
            <div>
                <Link to="../couponmanagement" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen ">Back</Link>
            </div>
        </div>
        <div className="card bg-base-100 shadow-md p-6 mt-8">
            <form onSubmit={(e) => {updatedcoupon(id);e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          
                   
                    <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Coupon Name<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Coupon Name"
                        required
                        placeholder="Coupon Name" 
                        onChange={(e) => getname(e.target.value)}
                        value={name}
                        id="Coupon Name" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    {/* <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Coupon Code<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Coupon Code"
                        required
                        placeholder="Coupon Code" 
                        onChange={(e) => getcode(e.target.value)}
                        value={code}
                        id="Coupon Code" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div> */}
                    <div>
                        <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Discount Type<span className="text-[#f00]">&nbsp;*</span></label>
                        <select 
                          name="Discount Type"
                        required
                        onChange={(e) => getdiscount_type(e.target.value)}
                        value={discount_type}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            <option value={'flat'}>Flat</option>
                            <option value={'percentage'}>Percentage</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Discount<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Discount"
                        required
                        placeholder="Discount" 
                        onChange={(e) => getdiscount(e.target.value)}
                        value={discount}
                        id="Discount" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
              <label
                htmlFor="date"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Start Date<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="startdate"
                placeholder=""
                onChange={(e) => {getstartdate(e.target.value);setstartdatestate(e.target.value)}}
                value={startdate}
                
                max="2025-01-01"
                id="startdate"
                type="date"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                End Date<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="enddate"
                placeholder=""
                onChange={(e) => getenddate(e.target.value)}
                // defaultValue={<Moment format="YYYY-MM-DD">{{updatedEndDate}}</Moment>}
                value={enddate}
                disabled={startdate === "" ? true: false}
                min={new Date(startdate ? startdate : null).toISOString().split('T')[0]}
                max="2025-01-01"
                id="enddate"
                type="date"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
        
                
                    <div>
                        <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
                        <select 
                        name="Status"
                        required
                        onChange={(e) => getstatus(e.target.value)}
                        value={status}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            <option value={'1'}>Active</option>
                            <option value={'0'}>Inactive </option>
                        </select>
                    </div>
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../couponmanagement"
              className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            <button className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4">
              <MdOutlineCheck className="mr-1" />
              Update
            </button>
          </div>
            </form>
        </div>
   </>
  )
});
