import {createContext,useContext} from 'react'
import { makeAutoObservable,observable, runInAction } from "mobx";
import {postLogin, getMethodData,patchMethodData, postMethodData, postFormData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class AuthStore {
    user={};
 
    errLoggingIn = "";
    loginLoading = false;
    current_leagues="";
    matches_this_week="";
    tolal_income="";
    tolal_leagues="";
    tolal_matches="";
    tolal_players="";
    tolal_seasons="";
    tolal_users="";
    adminAuthApproved = false;
    Token = null;
    getforgotpas=false;
    otpscreen= false;
    email = "";
    passchangedsuccesfull = false;
    verifybtn = false;
    ForgotOTP = { forgototp: null };
    password = "";
    confirmresetpas="";
    otpTimeStatus = false;
    passwordConfirm=false;
    errSignup="";
    passwordLoading = false;
    errPassword = "";
    Forgotpass="";
    currentpassword = "";
    newpassword = "";
    confirmnewpassword = "";
    passwordpopup = false;
    strongRegex = new RegExp( "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    notificationcount = ""

   
    addloadprofile = false;
    erraddprofile = "";
    firstname= "";
    firstnameapi= "";
    email = "";
    lastname  = "";

    errloadprofile = "";
    loadprofile = false;
    
    
    constructor(){
        makeAutoObservable(this)
        this.loadAdmin()
       
    }

    // load admin data
    loadAdmin = async () => {
        if(!localStorage.getItem("Admin")){
            runInAction(() => {
                this.adminAuthApproved = false
            });
            return;
        }
        runInAction(() => {
            this.Token = localStorage.getItem("Admintoken")
            this.user = localStorage.getItem("Admin") 
            this.adminAuthApproved = true
        });
    };

    // get profile data
    getprofile =(text) =>{
        this.user = text
    }
    Forgotui=(text)=>{
        this.getforgotpas = text;
        this.passwordConfirm = false;
        this.otpscreen=false;
    }
    // get email value
    emailChanged = (text) => {
        this.email = text
    };
    // get forgot email
    getemail =(text)=>{
       this.Forgotpass= text
    }
         // get Forgot password value
         Confirmpassw = (text) => {
            this.confirmresetpas = text
        };

    // get password value
    passwordChanged = (text) => {
        this.password = text
    };

    // getcurrentpassword value
    getcurrentpassword = (text) => {
        this.currentpassword = text
    };

    // getnewpassword value
    getnewpassword = (text) => {
        this.newpassword = text
    };


    //get updated name
  updatedfirstname = (text) => {
    this.firstname = text;
  };

  //get email
  getemail = (text) => {
    this.email = text;
  };

  // get lastname name
  getlastname = (text) => {
    this.lastname = text;
  };

        // get opt
        setforgotOTP = (forgototp) => {
            this.ForgotOTP = {forgototp};
            this.errSigningup = "";
            if(forgototp.length === 4){
                this.verifybtn = true
            }
            else{
                this.verifybtn = false
            }
        }  

    // getconfirmnewpassword value
    getconfirmnewpassword = (text) => {
        this.confirmnewpassword = text
    };

    setpasswordpopup = () => {
        this.passwordpopup = !this.passwordpopup
        this.errPassword = ""
        // this.currentpassword = ""
        // this.newpassword = ""
        // this.confirmnewpassword = ""
    }


    // admin login api
    loginadmin = async () => {
        runInAction(() => {
            this.loginLoading = true
            this.errLoggingIn = ""
        })
        postLogin("/admin/login",{
            "email":this.email,
            "password":this.password,
        }).then((resp) => {
   
            if(resp?.data?.status === false){
             
                runInAction(() => {
                    this.loginLoading = false;
                    this.errLoggingIn = resp?.data?.message;
                    toast.error(resp.data.message);
                })
                return;
            }
            if(resp?.data?.status === true){
                
                runInAction(() => {
                    this.loginLoading = false;
                    this.adminAuthApproved = true;
                    this.errLoggingIn = resp?.data?.message;
                    this.Token = resp?.data?.data?.token;
                    localStorage.setItem("Admin",JSON.stringify(resp.data.data))
                    localStorage.setItem("Admintoken",resp.data.data.token)
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.loginLoading = false;
            })
            }).catch((error) => {
                runInAction(() => {
                    this.loginLoading = false;
                    if(!error.response){
                        this.errLoggingIn = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoggingIn = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    };
      // get Coupon list
  getCoupon = () => {
    runInAction(() => {
      this.errLoggingIn = true;
      this.errloadcoupon = "";
      this.saved = false;
    });
    getMethodData("/api/admin/dashboard", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.current_leagues= resp?.data?.data?.current_leagues;
            this.matches_this_week= resp?.data?.data?.matches_this_week;
            this.tolal_income= resp?.data?.data?.tolal_income;
            this.tolal_leagues= resp?.data?.data?.tolal_leagues;
            this.tolal_matches= resp?.data?.data?.tolal_matches;
            this.tolal_players = resp?.data?.data?.tolal_players;
            this.tolal_seasons= resp?.data?.data?.tolal_seasons;
            this.tolal_users= resp?.data?.data?.tolal_users;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addseasoncourt = false;
          this.loadSeason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCoupon = false;
          if (!error.response) {
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          this.errloadcoupon = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };

    // admin password change api
    passwordChange = async () => {
        if(this.newpassword != this.confirmnewpassword){
            runInAction(() => {
                this.errPassword = "The New password and confirmation password do not match.";
            })
            return;
        }
        if (this.strongRegex.test(this.newpassword)) {
            const formData = new FormData();
            formData.append("old_password",this.currentpassword)
            formData.append("new_password",this.newpassword)

            runInAction(() => {
                this.passwordLoading = true;
                this.errPassword = "";
            })
            postFormData("/admin/change-password",formData,this.Token).then((resp) => {
                if(resp?.data?.status === false){
                    runInAction(() => {
                        this.passwordLoading = false;
                        this.errPassword = resp?.data?.message;
                        toast.error(resp.data.message);
                    })
                    return;
                }
                if(resp?.data?.status === true){
                    runInAction(() => {
                        this.passwordLoading = false;
                        this.setpasswordpopup();
                        toast.success(resp.data.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.passwordLoading = false;
                })
            }).catch((error) => {
                runInAction(() => {
                    this.passwordLoading = false;
                    if(!error.response){
                        this.errPassword = error?.message;
                        toast.error(error?.message);
                    }
                    this.errPassword = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
        }
        else{
            this.errPassword="( 8 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter )"
        }
      
    };

    // admin logout
    logout = () => {
        runInAction(() => {
            this.adminAuthApproved = false;
            this.errLoggingIn = "";
            this.Token = ""
            localStorage.removeItem("Admin");
            localStorage.removeItem("Admintoken");
            // toast.success("Logout Successfully");
        })
        return;
    };
        //Forgot password
        Forgotpassword = async () => {
            runInAction(() => {
                this.loginLoading = true;
                this.errSignup = "";
                this.otpscreen = false;
                this.otpTimeStatus = true;
                this.errwrongemail = false;
                this.verifybtn = false;
                this.passwordConfirm=false;
            })
            postLogin("/admin/get-otp",{
                "email":this.Forgotpass,
                 
            }).then((resp) => {
                if(resp?.data?.status === false){
                    runInAction(() => {
                        this.loginLoading = false;
                        this.errSignup = resp?.data?.message;
                        toast.error(resp.data.message);
                    })
                    return;
                }
                if(resp?.data?.status === true){
                
                    runInAction(() => {
                        this.loginLoading = false;
                        this.otpscreen = true;
                        this.errSignup = resp?.data?.message;
                        // toast.success(resp.data.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.loginLoading = false;
                    this.errSignup = resp?.data?.message;
                    toast.error(resp.data.message);
                  
                })
                }).catch((error) => {
                    runInAction(() => {
                        this.loginLoading = false;
                        this.errSignup = error?.response?.data?.message;
                        toast.error(error?.response?.data?.message);
                        this.errwrongemail = "User not found";
                    })
                })
        };
         // Reset password
 resetpass = async () => {
    if(this.password != this.confirmresetpas){
        runInAction(() => {
            this.errPassword = "The New password and confirmation password do not match.";
        })
        return;
    }
    if (this.strongRegex.test(this.password)) {
        runInAction(() => {
            this.loginLoading = true;
            this.errLoggingIn = "";
            this.authapproved = false;
            this.errPassword = ""
            this.passchangedsuccesfull = false;
        })
        postLogin("/admin/reset-password",{
            "email":this.Forgotpass,
            "password":this.password,
        }).then((resp) => {
            if(resp?.data?.status === false){
                runInAction(() => {
                    this.loginLoading = false;
                    this.errLoggingIn = resp?.data?.message;
                    this.authapproved = false;
                    this.passchangedsuccesfull = false;
                    toast.error(resp?.data?.message);
                })
                return;
            }
            if(resp?.data?.status === true){
                runInAction(() => {
                    this.loginLoading = false;
                    this.passchangedsuccesfull = true;
                    this.errLoggingIn = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getforgotpas = true;
                  
                 
                })
                return;
            }
            runInAction(() => {
                this.loginLoading = false;
                this.errLoggingIn = resp?.data?.message;
                toast.error(resp?.data?.message);
            })
            }).catch((error) => {
                runInAction(() => {
                    this.loginLoading = false;
                    this.errLoggingIn = error?.response?.data?.message;
                    // toast.error(error?.response?.data?.message);
                })
            })
    }
    else{
        this.errPassword="( 8 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter )"
    }
    
};
 //verify forgot pass otp
 otpForgotpassVerify = async () => {
    runInAction(() => {
        this.loginLoading = true;
        this.errSigningup = "";
    })
    postLogin("/admin/verify-otp",{
        "email":this.Forgotpass,
        "otp":this.ForgotOTP.forgototp
    }).then((resp) => {
        if(resp?.data?.status === false){
            runInAction(() => {
                this.loginLoading = false;
                this.errSigningup = resp?.data?.message;
                toast.error(resp?.data?.message);
            })
            return;
        }
        if(resp?.data?.status === true){
            runInAction(() => {
                this.loginLoading = false;
                this.passwordConfirm = true;
                this.errSigningup = resp?.data?.message;
                // this.Token = resp?.data?.data?.token;
                // this.user = resp.data?.data;
                // localStorage.setItem("user",JSON.stringify(resp.data.data))
                // localStorage.setItem("token",JSON.stringify(resp.data.data.token))
                // toast.success(resp.data.message);
            })
            return;
        }
        runInAction(() => {
            this.loginLoading = false;
            this.errSigningup = resp?.data?.message;
            toast.error(resp?.data?.message);
        })
        }).catch((error) => {
            runInAction(() => {
                this.loginLoading = false;
                this.errSigningup = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
        })
   
};
      // resendOtp forgotpass

      resendOtpForgotPass = async () => {
        runInAction(() => {
            this.otpTimeStatus = true;
            this.ForgotOTP.forgototp = "";
            this.errSigningup = "";
            this.verifybtn = false
        })
        postLogin("/admin/get-otp",{
            "email":this.Forgotpass
        }).then((resp) => {
            if(resp?.data?.status === false){
                runInAction(() => {
                    // toast.error(resp.data.message);
                })
                return;
            }
            if(resp?.data?.status === true){
                runInAction(() => {
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                toast.error(resp.data.message);
            })
            }).catch((error) => {
                runInAction(() => {
                    toast.error(error?.response?.data?.message);
                })
            })
    }
         // get Notification List
  getNotificationCount = () => {
   
    getMethodData("/admin/notifications/get-unread-count", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
           
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.notificationcount = resp?.data?.data?.count
          });
          return;
        }
        runInAction(() => {
            
        });
      })
      .catch((error) => {
        runInAction(() => {
            
        });
        return;
      });
  };

  
  //get profile 
  getprofileone =() => {
    getMethodData( `/admin/get-profile`, this.Token)
    .then((resp) => {
        runInAction(() => {
            this.loadprofile = true;
            this.errloadprofile = "";
          });
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadprofile = false;
          this.errloadprofile = resp.data.message;
          toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadprofile = false;
          this.errloadprofile = resp?.data?.message;
          // this.upadateprofile= resp.data.data;
          this.firstname =resp?.data?.data?.first_name;
          this.firstnameapi =resp?.data?.data?.first_name;
          this.lastname = resp?.data?.data?.last_name;
          this.email = resp?.data?.data?.email;
          // this.status = resp?.data?.data?.isActive;
        })
        return;
      }
      runInAction(() => {
        this.loadprofile = false;
        this.errloadprofile = resp.data.message;
        toast.error(resp.data.message);
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.loadprofile = false;
            this.errloadprofile = error?.message;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.loadprofile = false;
            this.errloadprofile = error?.response?.data?.message;
      })
      return;
  })
}


//update method
updateprofile = () => {
  const formData = new FormData();
  // Object.keys(data).forEach((key) => {
  //   if(key === "image"){
  //     if (data.image && data.image.length > 0) {
  //       formData.append("image", data.image[0]);
  //     } else {
  //       formData.delete("image");
  //     }
  //   }
  //   else {
  //     if (!appendedKeys.includes(key)) {
  //       formData.append(key, data[key]);
  //       appendedKeys.push(key); // add to the appended keys list
  //     }
  //   }
  // });
  formData.append("first_name",this.firstname)
  formData.append("last_name",this.lastname)
    runInAction(() => {
      this.addloadprofile = true;
      this.erraddprofile = "";
    });
    postFormData("/admin/update-profile",
      formData,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloadprofile = false;
            this.erraddprofile = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addloadprofile = false;
              this.erraddprofile = resp?.data?.message;
              toast.success(resp.data.message);
              this.getprofileone()
              // this.getCity();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addloadprofile = false;
          this.erraddprofile = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.addloadprofile = false;
              this.erraddprofile = error?.message;
              toast.error(error?.response?.data?.errors[0]);
            }
              toast.error(error?.response?.data?.errors[0]);
              this.addloadprofile = false;
              this.erraddprofile = error?.response?.data?.message;
        })
        return;
    })
  };


};


export const AuthStoreContext = createContext(new AuthStore());
export const useAuthStore = () => useContext(AuthStoreContext);