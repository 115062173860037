import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { useCategoryStore, CategoryStore, CategoryStoreContext } from "./store/CategoryStore";
import { observer } from "mobx-react";

const EditCategoryManagement = observer(() => {
    const { id } = useParams();
    const store = new CategoryStore({
        isloading: false,
        editloading: true,
        id: id
      });
    return (
        <CategoryStoreContext.Provider value={store}>
            <Screen />
        </CategoryStoreContext.Provider>
    )
})

export default EditCategoryManagement;

const Screen = observer(() => {
    const { id } = useParams();
    const { 
        name,
        status,
        keywords,
        image,
        imageurl,
        saved,
        loadCategory,
        setprice,
        price,
        setProductType,
        Producttypelist,
        ProductType,
        setname,
        setstatus,
        setkeywords,
        setimage,
        editcategory,
        setcheckbox,
        checkbox
      } = useCategoryStore();

      const navigate = useNavigate();
      useEffect(() => {
        if (saved) {
          navigate("../categorymanagement");
        }
      }, [saved,loadCategory]);
      const handleCheckboxChange = (e) => {
        const value = e.target.checked ? 1 : 0;
        setcheckbox(value);
      };
    return (
        <>
        <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold text-moredarkgreen">Edit Category</p>
                {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
            </div>
            <div>
                <Link to="/categorymanagement" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen ">Back</Link>
            </div>
        </div>  
            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {editcategory(id);e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label htmlFor="ProductType" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Product Type<span className="text-[#f00]">&nbsp;*</span></label>
                            <select
                                required
                                id="ProductType"
                                name="ProductType"
                                value={ProductType}
                                onChange={(e)=> {setProductType(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                {/* <option value="" hidden className="">Select Product Type</option> */}
                                {Producttypelist && Producttypelist.map((res,index)=> (
                                    <option key={index} value={res?.id}>{res?.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Name<span className="text-[#f00]">&nbsp;*</span></label>
                            <Input
                                name="name"
                                required
                                value={name}
                                onChange={(e)=> {setname(e.target.value)}}
                                placeholder="Name"
                                id="name"
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="keywords" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Keywords<span className="text-[#f00]">&nbsp;*</span></label>
                            <Input
                                name="keywords"
                                required
                                value={keywords}
                                onChange={(e)=> {setkeywords(e.target.value)}}
                                placeholder="keywords"
                                id="keywords"
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Price<span className="text-[#f00]">&nbsp;*</span></label>
                            <Input
                                name="Price"
                                disabled={ProductType == "3" || ProductType == "4"  ? false : true}
                                placeholder="Price"
                                id="Price"
                                min="0.1"
                                title="Value must be greater than 0.1"
                                step="any"
                                value={price}
                                onChange={(e)=> {setprice(e.target.value)}}
                                type="number"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
                            <select
                                id="Status"
                                required
                                name="Status"
                                value={status}
                                onChange={(e)=> {setstatus(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                {/* <option value={status} className=" hidden">{status ? "Active" : "Inactive"}</option> */}
                                <option value="1">Active</option>
                                <option value="0">Inactive </option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="image" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Image<span className="text-[#f00]">&nbsp;*</span></label>
                            <div className=" grid grid-cols-3">
                            <Input
                                name="image"
                                placeholder="Image"
                                id="image"
                                accept="image/*"
                                onChange={(e)=> {setimage(e.target.files)}}
                                type="file"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md col-span-2"
                            />
                            <img className="w-full" src={ imageurl }/>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="checkbox" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Popular Category</label>
                            <div className=" grid grid-cols-3">
                            <input
                                name="checkbox"
                                id="checkbox"
                                // defaultValue=""
                                defaultChecked={checkbox === 1 }
                                onChange={handleCheckboxChange}
                                checked={checkbox === 1}
                                type="checkbox"
                                autoComplete="off"
                                className="focus:outline-0 w-5 h-5 text-sm font-medium rounded-md "
                            />
                            </div>
                        </div>
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="/categorymanagement" className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineClose className="mr-1"/>Cancel</Link>
                        <button type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4" ><MdOutlineCheck className="mr-1"/>Update</button>
                    </div>
                </form>
            </div>

        </>
    )
})
