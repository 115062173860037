import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData, postMethodData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class WithdrawalStore {
    Token = null;
    BankDetails=[];
    Statuspopup=false;
    UserLoader=false;
    loadWithdrawaL= false;
    errloadPayments = "";
    saved= false;
    nolist=false;
    Withdrawal=[];
    orderone={};
    userid=""
    transactionid="";
    orderid="";
    fromdate="";
    todate="";
    customername="";
    customeremail="";
    status="";

    

    constructor(isloading = false) {
        makeAutoObservable(this);
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloading){
            this.getwithdrawalList();
            return
        }
        // if(isloading.editloading){
        //     this.getOrderone(isloading?.id)
        // }
    }

    setstatusVoidpopup = (e) => {
        this.Statuspopup = e;
      };
 
    setfromdate = (text) =>  {
        this.fromdate = text;
    }
    settodate = (text) =>  {
        this.todate = text;
    }
    setcustomername = (text) =>  {
        this.customername = text;
    }
    setcustomeremail = (text) =>  {
      this.customeremail = text;
  }
    setstatus = (text) =>  {
        this.status = text;
    }
    getstatusid = (text) => {
        this.userid = text;
      }
 setBankDetails=(text)=>{
    this.BankDetails=text;
 }
    // get order List
    getwithdrawalList = () => {
        runInAction(() => {
            this.loadWithdrawaL= true;
            this.errloadWithdrawaL = "";
            this.saved = false;
            });
        getMethodData("/admin/withdrawals",this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                this.loadWithdrawaL = false;
                this.errloadWithdrawaL = resp?.data?.message
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                this.loadWithdrawaL = false;
                this.errloadWithdrawaL = resp?.data?.message;
                // console.log(resp?.data?.data.length);
                if(resp?.data?.data?.data?.length === 0) {
                    this.nolist = true;
                }else{
                    this.nolist = false;
                }
                // console.log(resp?.data?.data?.data)
                this.Withdrawal = resp?.data?.data?.data;
                this.saved = false;
                // this.status = true;
                });
                return;
            }
            runInAction(() => {
                this.loadWithdrawaL = false;
                this.errloadWithdrawaL = resp?.data?.message;
            });
            })
            .catch((error) => {
            runInAction(() => {
                this.loadWithdrawaL = false;
                if(!error.response){
                    this.errloadWithdrawaL = error?.message;
                    toast.error(error?.message);
                }
                this.errloadWithdrawaL = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };


        //filter Order api 

        getFilterWithdrawal = (page) => {
            runInAction(() => {
                this.loadWithdrawaL = true;
                this.errloadWithdrawaL = "";
                this.saved = false;
              });
            getMethodData(`/admin/withdrawals?customer=${this.customername}&email=${this.customeremail}&status=${this.status}&from_date=${this.fromdate}&to_date=${this.todate}`,this.Token)
            .then((resp) => {
      
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadWithdrawaL= false;
                    this.errloadWithdrawaL = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadWithdrawaL = false;
                        this.errloadWithdrawaL = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                        this.Withdrawal = resp?.data?.data?.data;
                        this.saved = false;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadWithdrawaL = false;
                  this.errloadWithdrawaL = resp?.data?.message;
                });
              })
              .catch((error) => {
                runInAction(() => {
                    this.loadWithdrawaL = false;
                    if(!error.response){
                        this.errloadWithdrawaL = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadWithdrawaL = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };
        //Active and inactive
statusChange = () => {
    runInAction(() => {
      this.Statuspopup = true;
      this.UserLoader=true;
      this.errloaduser = "";
    });
    postMethodData(`/admin/withdrawals/mark-completed`,{ withdrawal_id : `${this.status}` },this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.UserLoader=true;
  
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.UserLoader=false;
            this.Statuspopup = false;
  
            this.errloaduser = resp?.data?.message;
            this.getwithdrawalList();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.UserLoader=true;
  
          this.errloaduser = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.UserLoader=true;
              this.Statuspopup = true;
  
              this.errloaduser = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.UserLoader=true;
              this.Statuspopup = true;
              this.errloaduser = error?.response?.data?.message;
        })
        return;
    })
  };
};


export const WithdrawalStoreContext = createContext(new WithdrawalStore());
export const useWithdawalStore = () => useContext(WithdrawalStoreContext);